.page {
    margin: 0 1rem;
    text-align: center;
}

.page h2 {
    font: 1.5rem/2.5rem 'Roboto', 'Arial', sans-serif;
    color: rgb(88, 88, 88);
    margin: 0 auto 2rem;
}

/* Home */

.home h2 {
    font-size: calc(1.5rem + 1vw);
    margin-bottom: 4rem;
}

.home h3 {
    width: 100%;
    display: block;
}

.home .homeVideo {
    margin: 0 auto 4rem;
}

.home .homeVideo iframe {
    max-width: 100%;
}

.home > p:last-child {
    font-weight: 600;
}

/* Events */

.instructorCard {
    background: white;
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.instructorCard > div {
    padding: 1rem;
}

.instructorCard h4 {
    margin: 0 0 1rem;
}

.instructorCard p {
    margin: 0 auto 1rem;
}

.instructorCard p:last-child {
    margin-bottom: 0;
}

.instructorCard figure {
    margin: 0;
}

.instructorCard figure img {
    margin: 0 auto;
}

.instructorCard a {
    text-decoration: underline;
}

.events h2 {
    margin-bottom: 1rem;
}

.framingBlock {
    margin: 0 auto 4rem;
    max-width: 1000px;
}

.frameImage {
    margin: 0;
}

.frameImage img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

/* Private Parties */
.privateParties h3 {
    margin-bottom: 2rem;
}

.privateParties p {
    font: 400 1rem/1.5rem 'Roboto', 'Arial', sans-serif;
}

.privatePartiesGallery {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    max-width: 1000px;
    margin: 0 auto 2rem;
}

.privatePartiesGallery > div {
    padding: 50% 0;
    position: relative;
}

.privatePartiesGallery  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.privatePartiesExamples {
    border: 1px solid #ddd;
    padding: 2rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    margin: 0 auto 2rem;
    max-width: 500px;
    width: 100%;
}

.privatePartiesExamples ul {
    margin: 0;
}

/* About */

.about h3 {
    font: 900 2rem 'Roboto', 'Arial', sans-serif;
    color: gray;
    margin-bottom: 1rem;
}

.about .aboutProcessGallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content;
    max-width: 100%;
    margin: 2rem 0;
    grid-gap: 2rem;
}

.about .aboutProcessGallery figure {
    margin: 0;
}

.about .aboutProcessGallery img {
    max-width: 100%;
    height: auto;
    margin: 0;
    object-fit: cover;
    width: 100%;
}

.about .aboutInstruction {
    display: grid;
    border: 1px solid #ddd;
    margin: 0 auto 2rem;
    padding: 2rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    grid-gap: 1rem;
}

.about .aboutInstruction p:nth-child(2) {
    font-size: 0.9rem;
    text-decoration: underline;
}

.about .aboutFaq {
    max-width: 1000px;
    margin: 0 auto;
}

.about .aboutFaq h3 {
    margin-bottom: 1rem;
}

.about .aboutFaq h4 {
    text-align: left;
    margin-bottom: 0;
}

.about .aboutFaq p {
    text-align: left;
    margin-bottom: 1rem;
    color: #555;
}

/* Kids */

.kids .kidsPaintingGallery, .kids .kidsLegoGallery {
    display: grid;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

.kids .kidsPaintingGallery img, .kids .kidsLegoGallery img {
    max-width: 100%;
    margin: 0;
    height: 100%;
    object-fit: cover;
}

.kids p em {
    font-style: italic;
}

.kids h3 {
    text-align: left;
    max-width: 1000px;
    margin: 0 auto 1rem;
}

.kids ul {
    text-align: left;
    list-style-type: circle;
    max-width: 1000px;
    margin: 0 auto 1rem;
}

.kids ul li {
    margin-left: 1rem;
}

.kids hr {
    max-width: 1000px;
    margin: 0 auto 1rem;
}

.kids .kidsLegoLogo {
    margin: 0 auto 1rem;
    max-width: 1000px;
}

.kids .kidsLegoLogo img {
    margin: 0;
    max-width: 70px;
    height: auto;
}

.kids .kidsAddOns h3 {
    text-align: center;
}

/* Event */

.singleEvent {
    margin: 0 auto 4rem;
    max-width: 1000px;
}

.singleEvent > div:first-child {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
}

.eventImage {
    margin: 0;
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.eventImage img {
    max-width: 100%;
    height: auto;
}

.eventText {
    margin: auto 0;
}

.eventText .eventDate {
    font-size: 1.2rem;
    color: darkslategray;
    font-weight: 500;
    font-style: italic;
    margin-bottom: 1rem;
    border-bottom: 2px solid darkslategray;
    max-width: fit-content;
    padding-bottom: 4px;
}

.eventText h3{
    margin: 0 0 1rem;
    color: slategray;
}

.event .eventText p, .event .eventText h3 {
    text-align: left;
}

.page.contact {
    margin-bottom: 4rem;
}

.contact .contactGrid {
    display: grid;
    grid-gap: 1rem;
    width: 100%;
}

.contact .contactForm {
    width: 100%;
    max-width: 700px;
    margin: 0 auto 1rem;
}

.contactSuccess {
    align-self: center;
    font: 1.5rem 'Roboto', 'Arial', sans-serif;
    color: green;
}

.contactError {
    align-self: center;
    font: 1.5rem 'Roboto', 'Arial', sans-serif;
    color: red;
}

.textInput input, .textArea textarea, .numberInput input {
    background: lightgray;
    outline: none;
    border: none;
    height: 3rem;
    width: 100%;
    border-radius: 3px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    font: 1rem/1.5rem 'Roboto', 'Arial', sans-serif;
}

.textArea {
    height: 5rem;
    max-height: 5rem;
    min-width: 100%;
    max-width: 100%;
    resize: none;
}

.textArea > span, .textInput > span, .numberInput > span {
    display: block;
    margin-bottom: 0.5rem;
    text-align: left;
    text-transform: capitalize;
}

.contactForm button {
    width: fit-content;
    border: none;
    background: gray;
    padding: 1rem 1rem;
    font-size: 1rem;
    color: white;
    border-radius: 3px;
    text-transform: uppercase;
    display: block;
}

.contact .map {
    min-height: 300px;
    max-width: 100%;
}

.contact .text {
    margin-bottom: 1rem;
}

.contact .text span {
    display: block;
    line-height: 1.5rem;
}

/* Media Queries */
@media screen and (min-width: 750px) {
    .instructorCard {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 1000px;
        margin: 0 auto 4rem;
    }

    .instructorCard > div {
        padding: 2rem;
    }

    .instructorCard > div:first-child {
        height: fit-content;
        margin: auto;
    }

    .framingBlock {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .framingBlock > div:last-child {
        height: fit-content;
        margin: auto;
        padding: 2rem;
    }

    .about .aboutProcessGallery {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: 1000px;
        margin: 2rem auto 0;
    }

    .about .aboutInstruction {
        align-items: center;
    }

    .about .aboutInstruction div {
        grid-column: 1 / span 1;
    }

    .about .aboutInstruction figure {
        grid-column: 2 / span 1;
    }

    .kids .kidsPaintingGallery, .kids .kidsLegoGallery {
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 1000px;
        margin: 0 auto 1rem;
    }

    .singleEvent > div:first-child {
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
    }

    .singleEvent .eventImage {
        height: fit-content;
    }

    .contact .contactGrid {
        grid-template-columns: 1fr 1fr;
        max-width: 1000px;
        margin: 0 auto;
        grid-gap: 3rem;
    }
}

/* Clover Styles */

.seatPrice {
    font: bold 1rem 'Roboto', 'Arial', sans-serif;
    text-align: left;
    margin-bottom: 2rem;
}

.purchaseTotal {
    font: bold 1.25rem 'Roboto', 'Arial', sans-serif;
    text-align: left;
    margin-bottom: 2rem;
}

.clover-footer {
    display: none !important;
}

.field iframe {
    max-height: 6rem;
}

.field.quantity {
    height: 6rem;
}

.field.quantity input, .field.first-name input, .field.last-name input {
    background-color: #fff;
    padding: 0.5rem;
    height: 3rem;
    border-radius: 3px;
    border: 1px solid #eee;
    width: 100%;
    outline: none;
}

.button-container {
    text-align: left;
}

.button-container button {
    background: #156925;
    color: white;
    border-radius: 3px;
    border: none;
    padding: 1rem;
    font-size: 1.1rem;
    cursor: pointer;
}

.squareContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    border-radius: 10px;
    font-family: SQ Market, SQ Market, Helvetica, Arial, sans-serif;
  }
  
  .squareLink {
      display: inline-block;
      font-size: 18px;
      line-height: 48px;
      height: 48px;
      color: #ffffff;
      min-width: 212px;
      background-color: #006aff;
      text-align: center;
      box-shadow: 0 0 0 1px rgba(0,0,0,.1) inset;
      border-radius: 6px;
  }