.eventList {
    display: flex;
    flex-direction: column;
    max-width: 850px;
    margin: 0 auto;
}

.eventList .eventExcerpt {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
}

.eventList .eventExcerpt img {
    max-width: 300px;
    width: 50%;
}

.eventList .eventExcerpt .eventExcerptText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 100%;
}

.eventList .eventExcerpt .eventExcerptText p {
    font: 900 4vw 'Roboto', 'Arial', sans-serif;
    font-size: calc(1rem + 2vw);
    color: gray;
}

.eventList .eventExcerpt .eventExcerptText span {
    line-height: 1.5rem;
    font-size: calc(0.60rem + 1vw);
}

/*Upcoming Events*/
.upcomingEvents {
    display: grid;
    width: 100%;
    row-gap: 2rem;
    max-width: 1200px;
    margin: 1rem auto 4rem;
}

.upcomingEvents .eventExcerpt {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content;
    text-align: center;
    height: fit-content;
}

.upcomingEvents .eventExcerpt img {
    display: block;
    max-width: 100%;
    grid-row: 1 / span 2;
}

.upcomingEvents .eventExcerptText {
    grid-row: 1 / span 2;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home p a {
    text-decoration: underline;
}

.eventExcerpt span {
    font-weight: 500;
    font-size: 0.9rem;
    font-style: italic;
    margin-top: 1rem;
}

.upcomingEvents .eventExcerptText p {
    color: gray;
    margin: 0;
    text-transform: uppercase;
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    font-size: calc(2rem + 1vw);
    font-weight: 900;
    line-height: 2.5rem;
}

.upcomingEvents .eventExcerpt:first-child .eventExcerptText p {
    color: gray;
    margin: 0;
    text-transform: uppercase;
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    font-size: calc(2rem + 1vw);
    font-weight: 900;
    margin-bottom: 0.5rem;
}

/* Media Queries */
@media screen and (min-width: 750px) {
    .upcomingEvents {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content min-content;
        grid-gap: 2rem;
    }

    .eventExcerptText {
        padding: 1rem;
    }

    .eventExcerpt:first-child {
        grid-column: 1 / span 2;
        font-size: 2rem;
    }
}

@media screen and (min-width: 950px) {
    .upcomingEvents {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: min-content;
        grid-gap: 2rem;
    }

    .eventExcerpt {
        grid-template-rows: min-content min-content;
        grid-template-columns: 100%;
    }
    .upcomingEvents .eventExcerptText p {
        color: gray;
        margin: 0;
        text-transform: uppercase;
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        font-size: calc(0.75rem + 1vw);
        font-weight: 900;
        line-height: 1.75rem;
    }

    .upcomingEvents .eventExcerptText span {
        margin-top: 0.5rem;
    }

    .upcomingEvents .eventExcerpt:first-child .eventExcerptText p {
        color: gray;
        margin: 0;
        text-transform: uppercase;
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        font-size: calc(1rem + 1vw);
        font-weight: 900;
    }

    .eventExcerpt:first-child {
        grid-column: 1 / span 1;
        font-size: 1rem;
    }

    .eventExcerpt:first-child span {
        font-size: 0.9rem;
    }

    .eventExcerpt img {
        grid-row: 1 / span 1;
    }

    .eventExcerptText {
        grid-row: 2 / span 1;
    }
}

.event .eventText .soldOut p {
    text-align: center;
}

.event .eventText .soldOut p:first-of-type {
    font-style: italic;
}

.event .eventText .soldOut p {
    margin-bottom: 0.5rem;
}

.event .eventText .soldOut h3 {
    text-align: center;
    font: 600 2.5rem 'Roboto', 'Arial', sans-serif;
}

.event .eventText .soldOut a, .event .eventText .soldOut span {
    text-decoration: underline;
    cursor: pointer;
}

.paypalQuantity span {
    text-align: left;
    display: block;
    margin-bottom: 0.5rem;
}

.paypalQuantity input {
    background: lightgray;
    border-radius: 2px;
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    border: none;
    outline: none;
}

#modal-root {
    position: absolute;
    z-index: 100;
}

.availability {
    text-align: center;
    display: block;
    margin-bottom: 1rem;
    font: 1.25rem 'Roboto', 'Arial', sans-serif;
    color: gray;
}