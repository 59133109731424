.newsletter {
    border-top: 1px solid lightgray;
    padding-top: 1rem;
}

.emailInput > span:first-child {
    font: 0.9rem 'Roboto', 'Arial', sans-serif;
    margin-bottom: 0.5rem;
    display: block;
    white-space: nowrap;
}

.emailInput input {
    background: lightgray;
    padding: 1rem 0.5rem;
    box-sizing: border-box;
    width: 100%;
    border: none;
    height: 2rem;
    border-radius: 3px;
    margin-bottom: 0.5rem;
    outline: none;
}

.newsletter button {
    background: darkgray;
    border: none;
    padding: 1rem;
    display: block;
    color: white;
    text-transform: uppercase;
}
