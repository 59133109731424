/* Styling Root */

.allContent {
    width: unset;
    height: 100vh;
    overflow-y: auto;
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-columns: 100vw min-content;
    overflow-x: hidden;
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out;
}

.allContent.showMainNav {
    overflow-y: hidden;
}

/* Main Header */
.mainHeader {
    min-width: 100vw;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    max-width: 100%;
    display: grid;
    padding: 2rem;
    grid-template-columns: 1fr min-content;
}

.mainHeader .mainNavControl {
    align-self: center;
    margin-left: 0;
    display: block;
    cursor: pointer;
}

.mainLogo {
    justify-self: center;
}

.mainLogo img {
    max-width: 255px;
}

/* Main Navigation */
.mainNav {
    grid-column: 2 / span 1;
    grid-row: 1 / span 3;
    padding: 1rem;
    border-left: 1px solid lightgray;
    box-shadow: 0 0 50px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease-in-out;
}

.allContent.showMainNav .mainNav {
    box-shadow: 0 0 50px 0px rgba(0, 0, 0, 0.15);
}

.mainNav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
}

.mainNav ul li {
    padding-bottom: 1rem;
    white-space: nowrap;
}

.mainNav ul li a.active {
    font-weight: 500;
    cursor: unset;
}

/* Main Content */
.page {
    grid-row: 2 / span 1;
}

/* Main Footer */
.mainFooter {
    grid-row: 3 / span 1;
    padding: 1rem 2rem;
    border-top: 1px solid lightgrey;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.mainFooter ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    text-transform: uppercase;
    white-space: nowrap;
    align-self: center;
}

.footerNav {
    display: flex;
}

.footerNav ul {
    align-self: center;
}

.footerAddress {
    font-size: 0.8rem;
    align-self: center;
    line-height: 1rem;
}

.footerAddress a {
    text-decoration: underline;
    margin-bottom: 0.5rem;
}

.footerAddress span:first-child {
    font-weight: 500;
}

/* Media Queries */
@media screen and (min-width: 500px) {
    .allContent {
        grid-template-rows: min-content min-content 1fr min-content;
        grid-template-columns: 100%;
    }

    .mainHeader {
        grid-template-columns: 1fr 0px;
    }    

    .mainLogo img {
        width: calc(100% - 2rem);
        max-width: 400px;
    }

    .mainHeader .mainNavControl {
        margin-left: -2rem;
    }

    .page {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
    }

    .mainFooter {
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content;
    }

    .mainFooter ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 0.9rem;
        max-width: 700px;
        margin: 0 auto 1rem;
    }

    .mainFooter ul li {
        white-space: nowrap;
        display: block;
    }

    .footerAddress {
        text-align: center;
        width: 100%;
        font-size: 0.9rem;
        display: grid;
        grid-template-rows: min-content min-content min-content;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 500px;
        margin: 0 auto -1rem;
        row-gap: 0.25rem;
    }

    .footerAddress span:first-child {
        grid-column: 1 / span 3;
        margin: 0 auto;
    }

    .footerAddress a {
        grid-column: 1 / span 3;
    }

    .footerAddress span:nth-child(4) {
        grid-column: 1 / span 1;
        grid-row: 3;
    }

    .footerAddress span:nth-child(6) {
        grid-column: 2 / span 1;
        grid-row: 3;
    }
    
    .footerAddress span:nth-child(8) {
        grid-row: 3;
    }
}

.newsletter.main {
    display: block;
}

.mainOpenNewsleter {
    display: none;
    border: none;
    padding: 0.5rem 1rem;
    background: rgb(140, 140, 140);
    border-radius: 2px;
    text-transform: uppercase;
    color: white;
}

.newsletterModal {
    background: white;
    border-radius: 2px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
    width: fit-content;
    width: 300px;
}

.newsletterModal .newsletter {
    border-top: none;
    padding: 0;
    width: 100%;
}

.newsletterModal .newsletter label span:first-of-type {
    background: gray;
    color: white;
    display: block;
    padding: 2rem 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.newsletterModal .newsletter input {
    margin: 0 2rem;
    max-width: calc(100% - 4rem);
    margin-bottom: 1rem;
    padding: 1rem;
    height: auto;
}

.newsletterModal .newsletterCancel {
    background: none;
    color: gray;
    float: left;
    margin-left: 2rem;
    cursor: pointer;
}

.newsletterModal .newsletter button:last-of-type {
    margin-right: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    float: right;
}

.newsletterErrors {
    width: 100%;
    clear: both;
    margin-bottom: 2rem;
    padding: 0 2rem;
    font-weight: 500;
    color: red;
    text-align: center;
}

.newsletterErrors .success {
    color: green;
}

@media screen and (min-width: 750px) {

    .mainHeader .mainNavControl {
        display: none;
    }

    .mainNav {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        margin: 0 auto 2rem;
        width: 100%;
        max-width: 1000px;
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        padding: 0.5rem 2rem;
        border-left: none;
    }

    .mainNav ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mainNav ul li {
        padding-bottom: 0;
    }

    .newsletter.main {
        display: none;
    }
    .mainOpenNewsleter {
        display: block;
        cursor: pointer;
    }
}

.modalScrim {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
}

.successCard {
    background: white;
    padding: 2rem;
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    position: relative;
}

.successCard header {
    text-align: center;
    font: 1.25rem 'Roboto', 'Arial', sans-serif;
    margin-bottom: 0.5rem;
}

.successCard > span:first-of-type {
    font: 600 1rem 'Roboto', 'Arial', sans-serif;
    display: block;
    margin-bottom: 1rem;
    text-align: center;
}

.successCard ul {
    margin-bottom: 0.5rem;
    list-style: none;
}

.successCard ul li {
    list-style: none;
    margin-bottom: 0.5rem;
}

.successCard ul li:before {
    content: "\2022";
    color: gray;
    font-weight: bold;
    display: inline-block;
    width: 1rem;
}

.successCard ul li a {
    text-decoration: underline;
}

.successCard h4 {
    text-align: center;
}

.successCard .newsletter {
    margin-top: 1rem;
}

.successCard .closeModalButton {
    background: gray;
    color: white;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -1rem;
    left: 50%;
    right: 50%;
    cursor: pointer;
}

.imageModal {
    padding: 2rem;
    position: relative;
    max-height: 90vh;
    align-self: center;
    justify-self: center;
}

.imageModal img {
    max-width: 100%;
    max-height: 100%;
}

.imageModal span {
    border-radius: 50%;
    padding: 5px;
    background: white;
    box-sizing: border-box;
    position: absolute;
    bottom: calc(1.25rem - 2.5px);
    left: calc(50% - 2.5px);
    display: block;
    margin: 0 auto;
    cursor: pointer;
}