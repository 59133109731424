h3 {
    font: 1.5rem/2rem 'Roboto', 'Arial', sans-serif;
}

h4 {
    font: 1.25rem 'Roboto', 'Arial', sans-serif;
    margin-bottom: 0.5rem;
}

p {
    text-align: center;
    max-width: 950px;
    margin: 0 auto 1rem;
    font: 1.1rem/1.8rem 'Roboto', 'Arial', sans-serif;
}

ul {
    margin-bottom: 2rem;
}

ul li {
    line-height: 1.5rem;
}

blockquote.wp-block-quote {
    margin-bottom: 1rem;
}

blockquote.wp-block-quote p {
    font-style: italic;
    font-size: 1.25rem;
}

blockquote.wp-block-quote cite {
    font-size: 1.3rem;
    font-weight: 600;
}