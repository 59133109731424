html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    height: 100vh;
    background: linear-gradient(to top, #f1f5f9 0%,#ffffff 100%);
}

figure img {
  margin: 0 auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  overflow-x: hidden;
  height: 100vh;
  overflow-y: auto;
}